const portuguese = {
    welcomeMsg: "Bem-vindo",
    labelAction: "Ação",
    labelAlmost: "Você está quase lá!",
    labelAnalyze: "Analisar",
    labelAnalyzed: "Analisado",
    labelAnInconsistencyWasIdentifiedInTheDocument: "Uma inconsistência foi identificada no documento. Por favor, reprocesse para ter melhores resultados",
    labelApply: "Aplicar",
    labelApplyQuestionnaire: "Aplicar questionário",
    labelApplyingQuestionnaire: "Aplicando questionário",
    labelApplyingQuestionnaireWait: "Aplicando questionário, aguarde.",
    labelAskTheAi: "Pergunte a IA",
    labelAttentionDependingOnTheSpeed: "Atenção, dependendo da velocidade da sua conexão com a Internet e do tamanho do arquivo carregado, essa operação pode demorar alguns minutos.",
    labelAttentionPDFDisplayFailed: "Ocorreu uma falha ao carregar o PDF. Clique aqui para tentar novamente",
    labelAll: "Tudo",
    labelBack: "Voltar",
    labelCancel: "Cancelar",
    labelConfirm: "Confirmar",
    labelConsult: "Consultar",
    labelCreate: "Criar",
    labelClose: "Fechar",
    labelCloseHistory: "Fechar histórico",
    labelCloseSidebar: "Fechar barra lateral",
    labelClearSelection: "Limpar seleção",
    labelCharacter: "caractere",
    labelCharacters: "caracteres",
    labelCopy: "Copiar texto",
    labelDarkTheme: "Tema Escuro",
    labelDelete: "Excluir",
    labelDeleteHistory: "Deletar histórico",
    labelDescription: "Descrição",
    labelDescriptionDocumentNote: "Descrição (Única para múltiplos documentos)",
    labelDescriptionOrQuestionId: "Descrição, ID ou insira uma nova pergunta para cadastrar",
    labelDocumentTypeAlreadyExists: "Este tipo de documento já existe",
    labelDocumentTypeError: "Tipo de documento inválido",
    labelDocuments: "Documentos",
    labelDocumentName: "Nome do documento",
    labelDocumentType: "Tipo de documento",
    labelDocumentNameOrDescription: "Nome ou descrição do documento",
    labelDocumentHasAlreadyBeenStandardizedPreviously: "O Documento já foi normalizado anteriormente. <br/> Aguarde, você será redirecionado para a página inicial.",
    labelDownloadCsv: "Baixar CSV",
    labelDownloadSuccessfully: "Download com sucesso",
    labelDocumentTranscript: "Transcrição do documento",
    labelDuplicatedQuestionnaire: "Nome do questionário duplicado",
    labelEdit: "Editar",
    labelEdited: "Editado",
    labelEditedFail: "Falha ao editar",
    labelEditQuestion: "Editar pergunta",
    labelEditType: "Editar tipo",
    labelExceedDesc: "Descrição excedeu o limite de 250 caracteres",
    labelExceedTitle: "Título excedeu o limite de 50 caracteres",
    labelExpandHistory: "Expandir histórico",
    labelExpand: "Expandir",
    labelFillInThisField: "Preencha este campo.",
    labelFindingTheBestAnswer: "Encontrando a melhor resposta",
    labelFailedToNormalize: "Falha ao normalizar",
    labelFailedNoResponse: "Falha, sem resposta do servidor.",
    labelFailedToLoadHistory: "Falha ao carregar histórico.",
    labelFailedToApplyQuestionnaire: "Falha ao aplicar questionário.",
    labelGeneralInformation: "Informações Gerais",
    labelGoHome: "Ir para Home",
    labelHistoric: "Histórico",
    labelInclusionDate: "Data de inclusão",
    labelListing: "Listagem",
    labelLoading: "Carregando",
    labelLines: "Linhas por página",
    labelLoadingTheText: "Carregando o texto",
    labelLoadingFilePleaseWait: "Carregando o arquivo, por favor aguarde",
    labelManage: "Gerenciar",
    labelMostRecent: "Mais recentes",
    labelMostOlder: "Mais antigos",
    labelModel: "Modelos",
    labelName: "Nome",
    labelNew: "Novo",
    labelNewType: "Criar tipo",
    labelNewQuestion: "Criar pergunta",
    labelNewQuestionnaire: "Criar questionário",
    labelNotice: "Aviso",
    labelNoFileChosen: "Nenhum arquivo escolhido",
    labelNoQuestionsRegistered: "Não existem perguntas cadastradas",
    labelNoMoreQuestionsAvailable: "Não existem mais perguntas disponíveis",
    labelNoDocumentsWereFound: "Nenhum documento foi localizado",
    labelNoDocumentTypeWasFound: "Nenhum tipo de documento foi localizado",
    labelNoQuestionsWereFound: "Nenhuma pergunta foi localizada",
    labelNoQuestionsWereSelected: "Nenhuma pergunta foi selecionada.",
    labelNoQuestionnairesWereFound: "Nenhum questionário foi localizado",
    labelNotAnalyzed: "Não analisado",
    labelNotAllowed: "Não permitido",
    labelNormalizingTheDocument: "Normalizando o documento, aguarde.",
    labelNumberOfPagesHasBeenExceeded: "Número de páginas foi excedido. Contate o administrador do plano.",
    labelNumberOfQuestionsHasBeenExceeded: "Número de perguntas foi excedido. Contate o administrador do plano.",
    labelOutput: "Saída",
    labelOrder: "Ordenar",
    labelOwner: "Proprietário",
    labelPreparingTheDocument: "Preparando o documento..",
    labelPdfBack: "Voltar para o PDF",
    labelQueryWithoutHistory: "Consulta sem histórico",
    labelQuestions: "Perguntas",
    labelQuestionAlreadyExists: "Esta pergunta já existe",
    labelQuestionNotFound: "Pergunta não encontrada",
    labelQuestionnaires: "Questionários",
    labelQuestionnaireError: "Questionário inválido",
    labelQuestionnaireSearch: "Nome, ID , Tipo de documento ou insira um novo questionário para cadastrar",
    labelQuestionnaireAppliedSuccessfully: "Questionário aplicado com sucesso, aguarde.",
    labelQuestionnaireAndAi: "Questionários e IA",
    labelReduceHistory: "Reduzir histórico",
    labelReprocess: "Reprocessar",
    labelSave: "Salvar",
    labelSend: "Enviar",
    labelSendQuestion: "Enviar pergunta",
    labelSendingTheDocument: "Enviando o documento, aguarde.",
    labelSelect: "Selecione",
    labelSelectAFile: "Selecione um arquivo.",
    labelSelectAnItemFromTheList: "Selecione um item da lista.",
    labelSelected: "Selecionada",
    labelSelecteds: "Selecionadas",
    labelSelectQuestions: "Selecione as perguntas",
    labelSelectQuestionnaire: "Selecione um questionário",
    labelSearchDocument: "Buscar documento",
    labelSearchTypes: "Buscar tipos",
    labelSearchQuestion: "Buscar pergunta",
    labelSearchQuestionnaire: "Buscar questionário",
    labelSignOut: "Sair",
    labelStatus: "Status",
    labelShowingToTotal: "de",
    labelShowingFilesUpload: " arquivos baixados",
    labelStandardizedFullText: "Texto integral normalizado",
    labelTypes: "Tipos",
    labelTextCopiedToClipboard: "Texto copiado para a área de transferência.",
    labelThisActionCannotBeUndone: "Esta ação não poderá ser desfeita. <br/> Tem certeza que deseja <br/> removê-lo?",
    labelTypeNameOrId: "Nome, ID ou insira um novo tipo para cadastrar",
    labelTheFileMayBeCorrupt : "O Arquivo pode estar <br/> corrompido ou com erro. <br/> Por favor, tente novamente.",
    labelTheFileMayBeUnreadableOrHaveAnError: "O Arquivo pode estar <br/> ilegível ou com erro. <br/> Por favor, tente novamente.",
    labelThereIsNotEnoughCredit: "Não há crédito suficiente para aplicar todas as perguntas deste questionário.",
    labelTypeDoc: "Tipo de Documento",
    labelUpload: "Carregar",
    labelUploadPdf: "Arraste e solte seus arquivos PDF abaixo para começar ou clique na área para navegar",
    labelUploadFailure: "Falha de carregamento",
    labelUploadedFiles: "Todos os arquivos carregados",
    labelUploadedFilesError: "Falha ao carregar arquivos!",
    labelUploadComplete: "Carregamento completo",
    labelUploadError: "Algo está errado",
    labelViewQuestions: "Visualizar perguntas",
    labelViewHistory: "Exibir histórico",
    labelYouAreAboutToDeleteDocument: "Você está prestes a deletar <br/> o documento do sistema",
    labelYouAreAboutToDeleteDocumentType: "Você está prestes a deletar <br/> um tipo de documento do sistema",
    labelYouAreAboutToDeleteDocumentQuery: "Você está prestes a deletar <br/> o histórico de consulta do sistema",
    labelYouAreAboutToDeleteQuestion: "Você está prestes a deletar <br/> uma pergunta do sistema",
    labelYouAreAboutToDeleteQuestionnaire: "Você está prestes a deletar <br/> um questionário do sistema",
    labelRemoveAllDropzone: "Remover arquivos do dropzone",
    labelRemoveAllFilesDropzone: "Remover todos os arquivos da fila?",
    labelThisActionRemoveAllFiles: "Essa ação removerá todos os arquivos selecionados para upload",
    labelCaution: "Atenção!",
    labelNotReloadThePage: "Para não interromper o processo de upload, não recarregue a página",
    labelFailed: "falhou!",
    labelChangeTenant: "Alternar Tenant",
}

export default portuguese;
